/* eslint-disable unicorn/prefer-logical-operator-over-ternary */
/* eslint-disable camelcase */

const makeRequest = (url, param) => {
  return fetch(url, param)
  .then(response => {
    if (response.status === 401 || response.status === 404) {
      window.open('./login.html', '_self')
    } else if (response.status === 200) {
      return response.json()
    } else {
      const error = new Error(`We have some problem, code: ${response.status}`)
      error.response = response
      throw error
    }
  })
  .catch(error => {
    // eslint-disable-next-line no-console
    console.log('Error', error)
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const role = localStorage.getItem('role')

  // const pageWrapper = document.querySelector('[data-js="page-wrapper"]')
  const tableWrapper = document.querySelector('[data-js="table-wrapper"]')
  const paginWrapper = document.querySelector('[data-js="pagination-wrapper"]')
  const modalSaveBtn = document.querySelector('[data-js="save-btn"]')
  const modalSaveChangesBtn = document.querySelector('[data-js="save-changes-btn"]')

  let currentItemDataId
  let currentPage = 'https://fetlife.best/api/accounts?per-page=20&page=1'

  if (role === 'admin') {
    adminView()
  } else {
    userView()
  }

  renderContent('https://fetlife.best/api/accounts?per-page=20&page=1')
  fillSelect('https://fetlife.best/api/proxies?per-page=1000&page=1', '[data-select="proxy-list"]', 'ip', true)
  fillSelect('https://fetlife.best/api/models?per-page=1000&page=1', '[data-select="model-list"]', 'name', true)
  fillSelect('https://fetlife.best/api/slots?per-page=1000&page=1', '[data-select="slot-list"]', 'id')

  function fillSelect(url, selector, dataProp, emptyOption) {
    makeRequest(url)
    .then(data => {
      const wrappers = document.querySelectorAll(selector)

      if (emptyOption) {
        for (const wrap of wrappers) {
          const option = document.createElement('option')
          option.value = ''
          option.textContent = 'not selected'

          wrap.append(option)
        }
      }

      for (const item of data.data) {
        const option = document.createElement('option')
        option.value = item.id
        option.textContent = item[dataProp]

        wrappers[0].append(option)
      }

      for (const item of data.data) {
        const option = document.createElement('option')
        option.value = item.id
        option.textContent = item[dataProp]

        wrappers[1].append(option)
      }
    })
  }

  function adminView() {
    const tableHead = document.querySelector('[data-js="table"] > thead > tr')
    tableHead.innerHTML = `
    <th scope="col">ID</th>
    <th scope="col">User</th>
    <th scope="col">Name</th>
    <th scope="col">Email</th>
    <th scope="col">Password</th>
    <th scope="col">Url</th>
    <th scope="col">Verified</th>
    <th scope="col">Vip</th>
    <th scope="col">Model</th>
    <th scope="col">Proxy</th>
    <th scope="col">Slot(ID)</th>
    <th scope="col">Type</th>
    <th scope="col">Adspower(ID)</th>
    <th scope="col">Status</th>
    <th scope="col">Action</th>`

    const typeRows = document.querySelectorAll('[data-js="type-row"]')
    for (const row of typeRows) {
      row.classList.remove('hidden-row')
    }
  }

  function userView() {
    const typeRows = document.querySelectorAll('[data-js="type-row"]')
    for (const row of typeRows) {
      row.remove()
    }
  }

  modalSaveBtn.addEventListener('click', () => {
    const modalDataItems = document.querySelectorAll('#addNewItemModal [data-js="modal-data-item"]')

    const modalData = {
      model_id: modalDataItems[0].value,
      name: modalDataItems[1].value,
      email: modalDataItems[2].value,
      password: modalDataItems[3].value,
      url: modalDataItems[4].value,
      verified: modalDataItems[5].value,
      vip: modalDataItems[6].value,
      proxy_id: modalDataItems[7].value,
      slot_id: modalDataItems[8].value,
      type: role === 'admin' ? modalDataItems[9].value : 'work',
      adspower_id: role === 'admin' ? modalDataItems[10].value : modalDataItems[9].value,
      status: role === 'admin' ? modalDataItems[11].value : modalDataItems[10].value
    }

    makeRequest('https://fetlife.best/api/accounts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(modalData)
    })
    .then(() => {
      renderContent(currentPage)
    })

    for (const element of modalDataItems) {
      // eslint-disable-next-line no-unused-expressions
      element.classList.contains('form-select') ? element.value = (element.querySelector('option') ? element.querySelector('option').value : '') : element.value = ''
    }
  })

  modalSaveChangesBtn.addEventListener('click', () => {
    const modalDataItems = document.querySelectorAll('#editItemModal [data-js="modal-data-item"]')

    const modalData = {
      model_id: modalDataItems[0].value,
      name: modalDataItems[1].value,
      email: modalDataItems[2].value,
      password: modalDataItems[3].value,
      url: modalDataItems[4].value,
      verified: modalDataItems[5].value,
      vip: modalDataItems[6].value,
      proxy_id: modalDataItems[7].value,
      slot_id: modalDataItems[8].value,
      type: role === 'admin' ? modalDataItems[9].value : 'work',
      adspower_id: role === 'admin' ? modalDataItems[10].value : modalDataItems[9].value,
      status: role === 'admin' ? modalDataItems[11].value : modalDataItems[10].value
    }

    makeRequest(`https://fetlife.best/api/accounts/${currentItemDataId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(modalData)
    })
    .then(() => {
      renderContent(currentPage)
    })

    for (const element of modalDataItems) {
      // eslint-disable-next-line no-unused-expressions
      element.classList.contains('form-select') ? element.value = (element.querySelector('option') ? element.querySelector('option').value : '') : element.value = ''
    }
  })

  // eslint-disable-next-line complexity
  function createNewTR(data) {
    const newTR = document.createElement('tr')
    newTR.dataset.id = data.id

    // eslint-disable-next-line unicorn/prefer-ternary
    if (role === 'admin') {
      newTR.innerHTML = `
      <td>${data.id ? data.id : ''}</td>
      <td>${data.user ? data.user.name : ''}</td>
      <td>${data.name ? data.name : ''}</td>
      <td>${data.email ? data.email : ''}</td>
      <td>${data.password ? data.password : ''}</td>
      <td>${data.url ? data.url : ''}</td>
      <td>${data.verified ? data.verified : ''}</td>
      <td>${data.vip ? data.vip : ''}</td>
      <td>${data.model_id ? data.model.name : 'not selected'}</td>
      <td>${data.proxy_id ? data.proxy.ip : 'not selected'}</td>
      <td>${data.slot_id ? data.slot.id : ''}</td>
      <td>${data.type ? data.type : ''}</td>
      <td>${data.adspower_id ? data.adspower_id : ''}</td>
      <td>${data.status ? data.status : ''}</td>
      <td class="td-action">
        <button class="btn btn-primary btn-noevent-icon" type="button" data-js="edit-item-btn">
          <svg class="icon">
            <use xlink:href="node_modules/@coreui/icons/sprites/free.svg#cil-pencil"></use>
          </svg>
        </button>
        <div class="btn-group">
          <button type="button" class="btn btn btn-danger btn-noevent-icon dropdown-toggle" data-coreui-toggle="dropdown" aria-expanded="false">
            <svg class="icon">
              <use xlink:href="node_modules/@coreui/icons/sprites/free.svg#cil-trash"></use>
            </svg>
          </button>
          <ul class="dropdown-menu">
            <li><h6 class="dropdown-header">Are you sure?</h6></li>
            <li><div class="btn-group dropdown-confirm" role="group" aria-label="Basic outlined example">
              <button type="button" class="btn btn-outline-primary" data-js="delete-item-btn">Yes</button>
              <button type="button" class="btn btn-outline-primary">No</button>
            </div></li>
          </ul>
        </div>
      </td>`
    } else {
      newTR.innerHTML = `
      <td>${data.name ? data.name : ''}</td>
      <td>${data.email ? data.email : ''}</td>
      <td>${data.password ? data.password : ''}</td>
      <td>${data.url ? data.url : ''}</td>
      <td>${data.verified ? data.verified : ''}</td>
      <td>${data.vip ? data.vip : ''}</td>
      <td>${data.model_id ? data.model.name : 'not selected'}</td>
      <td>${data.proxy_id ? data.proxy.ip : 'not selected'}</td>
      <td>${data.slot_id ? data.slot.id : ''}</td>
      <td>${data.adspower_id ? data.adspower_id : ''}</td>
      <td>${data.status ? data.status : ''}</td>
      <td class="td-action">
        <button class="btn btn-primary btn-noevent-icon" type="button" data-js="edit-item-btn">
          <svg class="icon">
            <use xlink:href="node_modules/@coreui/icons/sprites/free.svg#cil-pencil"></use>
          </svg>
        </button>
        <div class="btn-group">
          <button type="button" class="btn btn btn-danger btn-noevent-icon dropdown-toggle" data-coreui-toggle="dropdown" aria-expanded="false">
            <svg class="icon">
              <use xlink:href="node_modules/@coreui/icons/sprites/free.svg#cil-trash"></use>
            </svg>
          </button>
          <ul class="dropdown-menu">
            <li><h6 class="dropdown-header">Are you sure?</h6></li>
            <li><div class="btn-group dropdown-confirm" role="group" aria-label="Basic outlined example">
              <button type="button" class="btn btn-outline-primary" data-js="delete-item-btn">Yes</button>
              <button type="button" class="btn btn-outline-primary">No</button>
            </div></li>
          </ul>
        </div>
      </td>`
    }

    newTR.addEventListener('click', event => {
      if (event.target.dataset.js === 'delete-item-btn') {
        makeRequest(`https://fetlife.best/api/accounts/${data.id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(() => {
          renderContent(currentPage)
        })
      } else if (event.target.dataset.js === 'edit-item-btn') {
        currentItemDataId = newTR.dataset.id

        const editModalBody = document.querySelector('#editItemModal tbody')

        editModalBody.querySelector('[name="model"]').value = data.model_id ? data.model_id : ''
        editModalBody.querySelector('[name="name"]').value = data.name
        editModalBody.querySelector('[name="email"]').value = data.email
        editModalBody.querySelector('[name="password"]').value = data.password
        editModalBody.querySelector('[name="url"]').value = data.url
        editModalBody.querySelector('[name="verified"]').value = data.verified
        editModalBody.querySelector('[name="vip"]').value = data.vip
        editModalBody.querySelector('[name="proxy"]').value = data.proxy_id ? data.proxy_id : ''
        editModalBody.querySelector('[name="slot"]').value = data.slot_id
        // eslint-disable-next-line no-unused-expressions
        editModalBody.querySelector('[name="type"]') ? editModalBody.querySelector('[name="type"]').value = data.type : null
        editModalBody.querySelector('[name="adspower"]').value = data.adspower_id
        editModalBody.querySelector('[name="status"]').value = data.status

        // eslint-disable-next-line no-undef
        const modalForEdit = new coreui.Modal('#editItemModal', {})
        modalForEdit.show()
      }
    })

    tableWrapper.append(newTR)
  }

  function renderContent(url) {
    currentPage = url
    makeRequest(url)
    .then(data => {
      tableWrapper.innerHTML = ''
      paginWrapper.innerHTML = ''

      for (const item of data.data) {
        createNewTR(item)
      }

      createPagination(data.meta.links)
    })
  }

  function createPagination(data) {
    if (data.length <= 3) {
      return
    }

    // eslint-disable-next-line unicorn/no-array-for-each
    data.forEach(el => {
      const newButton = document.createElement('li')
      newButton.classList.add('page-item')
      newButton.innerHTML = `<div class="page-link">${el.label}</div>`
      if (el.url) {
        newButton.addEventListener('click', () => renderContent(`${el.url}&per-page=20`))
      } else {
        newButton.classList.add('disabled')
      }

      if (el.active) {
        newButton.classList.add('active')
      }

      paginWrapper.append(newButton)
    })
  }
})
